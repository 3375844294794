import {
    mdiAlertCircleOutline, mdiFilterVariantPlus, mdiClose, mdiEyeOffOutline, mdiEyeOutline, mdiLockReset,
    mdiArrowRightCircleOutline, mdiBackspaceOutline, mdiArrowLeft, mdiAccount, mdiClockOutline, mdiLogoutVariant,
    mdiLogin, mdiDelete, mdiCheck, mdiOpenInNew, mdiChevronUp, mdiMagnify, mdiShareVariantOutline, mdiPlus, mdiMinus,
    mdiArrowRightThinCircleOutline, mdiGithub, mdiRegex, mdiStar, mdiStarOutline, mdiInformation
} from '@mdi/js';

export const IconPath = {
    mdiAlertCircleOutline, mdiFilterVariantPlus, mdiClose, mdiEyeOffOutline, mdiEyeOutline, mdiLockReset,
    mdiArrowRightCircleOutline, mdiBackspaceOutline, mdiArrowLeft, mdiAccount, mdiClockOutline, mdiLogoutVariant,
    mdiLogin, mdiDelete, mdiCheck, mdiOpenInNew, mdiChevronUp, mdiMagnify, mdiShareVariantOutline, mdiPlus, mdiMinus,
    mdiArrowRightThinCircleOutline, mdiGithub, mdiRegex, mdiStar, mdiStarOutline, mdiInformation
};

type IconPathTypes = typeof IconPath;
export type IconType = keyof IconPathTypes;

export enum IconSize {
    '2xs' = '2xs',
    xs = 'xs',
    s = 's',
    m = 'm',
    l = 'l',
    xl = 'xl',
    '2xl' = '2xl'
}
